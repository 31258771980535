import { ApexOptions } from 'apexcharts';
import ReactApexChart from 'react-apexcharts';
import React from 'react';
import { useFetch } from '../../../../hooks/useFetch';

export function Chart() {
  const { dataFetch, isFetching } = useFetch<any>({ baseUrl: 'escolas-rota', isArray: false });

  const options: ApexOptions = {
    chart: {
      type: 'area',
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
    },
    xaxis: {
      type: 'datetime',
      categories: [
        '2018-09-19T00:00:00.000Z',
        '2018-09-19T01:30:00.000Z',
        '2018-09-19T02:30:00.000Z',
        '2018-09-19T03:30:00.000Z',
        '2018-09-19T04:30:00.000Z',
        '2018-09-19T05:30:00.000Z',
        '2018-09-19T06:30:00.000Z',
      ],
    },
    tooltip: {
      x: {
        format: 'dd/MM/yy HH:mm',
      },
    },
  };

  return (
    <div style={{ marginRight: '1rem', marginTop: '1rem' }}>
      {isFetching || !dataFetch ? (
        'Carregando gráfico...'
      ) : (
        <ReactApexChart options={options} series={dataFetch} type="bar" height={350} />
      )}
    </div>
  );
}
