import React, { useRef, useState } from 'react';

import { Row, Col, Button, Input, Form as AntdForm, Select, DatePicker } from 'antd';
import { CardWrapper, FormWrapper } from '../styles';
import { api } from '../../../services/api';
import { SelectSegmento } from '../../../components/Select';
import { UploadComponent } from '../../../components/UploadComponent';
import { getSubDomain } from '../../../hooks/getSubDomain';
import { IHandleSubmitProps } from '../types';
import { UploadFile } from 'antd/lib/upload/interface';
import toast from 'react-hot-toast';
import { MultiSelect } from '../../../components/MultiSelect';
import { dados } from '../../../utility/env';
import { SelectPessoa } from '../../../components/Select/SelectPessoa';
import { SelectRotaTrajeto } from '../../../components/Select/SelectRotaTrajeto';
import { SelectEscola } from '../../../components/Select/SelectEscola';
import moment from 'moment';
import { SelectRota } from '../../../components/Select/SelectRota';
import { useAuth } from '../../../hooks/useAuth';

interface IFormInitialFields {
  idPessoa: string;
  idRota: string;
  idEscola: string;
  idRotaTrajeto: string;
  deficiente: string;
  idDeficiencia: string;
  atividadeInicio: moment.Moment;
  atividadeFim: moment.Moment;
}

interface IFormComponent {
  formType: 'put' | 'post' | 'view';
  initialFields?: IFormInitialFields;
  refetch: () => void;
  estudanteId?: string;
  setModalIsOpen: (event: boolean) => void;
}

export function Form({
  formType,
  setModalIsOpen,
  refetch,
  estudanteId,
  initialFields = {
    idEscola: '',
    deficiente: '',
    idPessoa: '',
    idRotaTrajeto: '',
    idDeficiencia: '',
    atividadeInicio: moment(),
    atividadeFim: moment(),
    idRota: '',
  },
}: IFormComponent) {
  const { user } = useAuth();

  const [isLoading, setIsLoading] = useState(false);

  const [idRota, setIdRota] = useState(initialFields.idRota);

  const formRef = useRef<any>(null);

  function clearFieldsAndCloseModal() {
    setModalIsOpen(false);
  }

  async function handleSubmit(data: IHandleSubmitProps) {
    setIsLoading(true);

    const formData = {
      ...data,
      idEscola: user?.idEscola ? user.idEscola : data.idEscola,
    };

    try {
      if (formType === 'post') {
        await api.post(getSubDomain() + 'estudantes', formData);
      } else {
        if (estudanteId) await api.put(getSubDomain() + `estudantes/${estudanteId}`, formData);
      }

      setModalIsOpen(false);

      formRef.current.resetFields();
      setIsLoading(false);
      refetch();
      //setFileListaCurriculo([]);
    } catch (err) {
      console.log(err);
    }

    setIsLoading(false);
    refetch();
  }

  console.log(initialFields);
  return (
    <FormWrapper>
      <AntdForm
        name="basic"
        onFinish={handleSubmit}
        style={{ padding: '30px' }}
        layout="vertical"
        initialValues={initialFields}
        ref={formRef}
      >
        <Row gutter={40}>
          <Col span={12}>
            <CardWrapper type="inner" title="Informações Gerais">
              <SelectPessoa
                name="idPessoa"
                defaultValue={initialFields.idPessoa}
                title="Pessoa"
                disabled={formType === 'view' ? true : false}
                fetchUrl="pessoas"
              />

              {!user?.idEscola && (
                <SelectEscola
                  name="idEscola"
                  disabled={formType === 'view' ? true : false}
                  title="Escola"
                  defaultValue={initialFields.idEscola}
                  fetchUrl="escolas"
                />
              )}

              <SelectRota
                name="idRota"
                onChange={e => setIdRota(e)}
                title="Rota"
                disabled={formType === 'view' ? true : false}
                fetchUrl="rotas"
              />

              <SelectRotaTrajeto
                name="idRotaTrajeto"
                disabled={formType === 'view' ? true : false}
                title="Trajeto"
                idRota={idRota}
                defaultValue={initialFields.idRotaTrajeto}
                // postUrl={getSubDomain() + "pessoas"}
                fetchUrl="rotatrajetos"
              />

              <AntdForm.Item
                label="Deficiente?"
                name="deficiente"
                rules={[{ required: true, message: 'Campo Obrigatório!' }]}
              >
                <Select placeholder="Selecione uma Opção" value={status} disabled={formType === 'view' ? true : false}>
                  <Select.Option value={1}>Sim</Select.Option>
                  <Select.Option value={0}>Não</Select.Option>
                </Select>
              </AntdForm.Item>
            </CardWrapper>
          </Col>

          <Col span={12}>
            <CardWrapper type="inner" title="Outras Informações">
              <AntdForm.Item
                label="Início da Atividade"
                name="atividadeInicio"
                rules={[{ required: true, message: 'Campo obrigatório' }]}
              >
                <DatePicker
                  format="DD/MM/YYYY"
                  placeholder="Selecione uma Data"
                  disabled={formType === 'view' ? true : false}
                />
              </AntdForm.Item>

              <AntdForm.Item
                label="Fim da Atividade"
                name="atividadeFim"
                rules={[{ required: true, message: 'Campo obrigatório' }]}
              >
                <DatePicker
                  format="DD/MM/YYYY"
                  placeholder="Selecione uma Data"
                  disabled={formType === 'view' ? true : false}
                />
              </AntdForm.Item>
            </CardWrapper>
          </Col>
        </Row>
        {formType == 'view' ? (
          <footer style={{ padding: '20px 30px', borderTop: '1px solid #f0f0f0' }}>
            <Button type="default" htmlType="button" onClick={clearFieldsAndCloseModal}>
              Fechar
            </Button>
          </footer>
        ) : (
          <footer style={{ padding: '20px 30px', borderTop: '1px solid #f0f0f0' }}>
            <Button type="default" htmlType="button" onClick={clearFieldsAndCloseModal}>
              Voltar
            </Button>

            <Button type="primary" htmlType="submit" loading={isLoading}>
              Salvar
            </Button>
          </footer>
        )}
      </AntdForm>
    </FormWrapper>
  );
}
