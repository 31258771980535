import React from 'react';
import { TableComponent } from './components/table';
import { ThemeLayout } from '../../layout/themeLayout';
import { Main, TableWrapper } from '../../container/styled';
import { PageHeader } from '../../components/page-headers/page-headers';
import { Cards } from '../../components/cards/frame/cards-frame';
import { useFetch } from '../../hooks/useFetch';
import { ExportButtonPageHeader } from '../../components/buttons/export-button/export-button';
import { ClearFilterButton } from './components/ClearFilterButton';
import { FilterButton } from './components/FilterButton';
import { IFetchPropsVeiculos } from '../veiculos/types';
import { Chart } from './components/Chart';

export function RelatorioVeiculosCondutor() {
  const {
    isFetching,
    dataFetch,
    refetch,
    currentPage,
    itensPerPage,
    filters,
    clearFilters,
    handleAddFilters,
    setCurrentPage,
    setColumnOrdenation,
    setItensPerPage,
    totalItens,
  } = useFetch<IFetchPropsVeiculos>({ baseUrl: "rotas/bus-driver-report" });
  return (
    <ThemeLayout>
      <PageHeader
        ghost
        title="Relatório de Onibus x Motorista"
        buttons={[
          <div key="1" className="page-header-actions">
            

            {filters.length !== 0 && <ClearFilterButton clearFilter={clearFilters} />}
            <FilterButton filters={filters} handleAddFilters={handleAddFilters} clearFilters={clearFilters} />
          </div>,
        ]}
      />

      <Main>
        <Cards headless>
          <Chart />
        </Cards>

        <Cards headless>
          <TableWrapper className="table-order table-responsive">
            <TableComponent
              setCurrentPage={setCurrentPage}
              setColumnOrdenation={setColumnOrdenation}
              currentPage={currentPage}
              refetch={refetch}
              isLoading={isFetching}
              dataFetch={dataFetch}
              totalItens={totalItens}
              itensPerPage={itensPerPage}
              setItensPerPage={setItensPerPage}
            />
          </TableWrapper>
        </Cards>
      </Main>
    </ThemeLayout>
  );
}
